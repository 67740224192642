.markdown-body strong {
  font-weight: bolder;
}

.markdown-body p {
  margin-top: 0;
  margin-bottom: 0.16rem;
}

.markdown-body .hljs-center {
  text-align: center;
}

.markdown-body .hljs-right {
  text-align: right;
}

.markdown-body .hljs-left {
  text-align: left;
}

.m-art-head {
  padding: 0.36rem 0.2rem 0;
}

.m-art-head h1 {
  margin-bottom: 0.36rem;
  color: #59b6d7;
  font-size: 0.5rem;
  letter-spacing: 1px;
}

.m-art-head p {
  font-size: 0.24rem;
  color: #ccc;
}

.m-art-cate {
  padding: 0.04rem;
  font-style: normal;
  display: inline-block;
  font-size: 0.2rem;
  height: 0.315789rem;
  margin-right: 0.1rem;
  color: #59b6d7;
  line-height: 0.235789rem;
  border: 1px solid currentColor;
  -webkit-transform-origin-x: 0;
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}

.m-art-subject {
  margin: 0.5rem 0.2rem 0.2rem;
  padding: 0.3rem;
  font-size: 0.26rem;
  line-height: 0.36rem;
  background-color: #f4f5f6;
  color: #999;
  border-left: 0.05rem solid #e3e3e3;
}

.m-art-subject span {
  color: #666;
}