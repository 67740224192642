.p-choose-category .m-cates {
  padding: 0.3rem;
}

.m-cates {
  margin-top: -0.3rem;
  margin-left: -0.3rem;
}

.m-cate {
  display: inline-block;
  padding: 0 0.1rem;
  margin-top: 0.3rem;
  margin-left: 0.3rem;
  width: -webkit-calc(1 / 4 * 100%  - 0.3rem);
  width: calc(1 / 4 * 100%  - 0.3rem);
  height: 0.6rem;
  line-height: 0.6rem;
  font-size: 0.28rem;
  background-color: #f4f5f5;
  border-radius: 0.08rem;
}

.m-cate span {
  overflow: hidden;
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}