.p-news .common-header {
  position: fixed;
}

.p-news .loadmore {
  padding-top: 1.7rem;
}

.p-news .loadmore.guest {
  padding-top: 0.9rem;
}