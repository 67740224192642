.p-post-news {
  min-height: 100vh;
}

.p-post-news .m-poster-box {
  position: relative;
  background: #f4f5f5;
  width: 3.8rem;
  height: 2.7rem;
  border: 1px solid #ededed;
  margin-bottom: 0.5rem;
}

.p-post-news .m-poster-box.loading img,
.p-post-news .m-poster-box.error img {
  opacity: 0.3;
}

.p-post-news .m-poster-box.error:after {
  content: "上传失败, 请重试";
  color: #f76c69;
  z-index: 9;
}

.p-post-news .m-poster {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.p-post-news .m-poster-placeholder {
  line-height: 1.5;
}

.p-post-news .step3 {
  padding: 0.8rem 0.3rem 0;
  color: #b3b3b3;
}

.p-post-news .step3 p {
  margin-bottom: 0.8rem;
}

.p-post-news .p-post-news-main > div {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}

.p-post-news .p-post-news-main .m-pos-f {
  top: 0.9rem;
}

.p-post-news .m-post-news-title {
  padding: 0.3rem 0.2rem;
}

.p-post-news .m-post-news-title input {
  font-size: 0.3rem;
  height: 100%;
  line-height: 0.36rem;
}

.p-post-news .m-post-news-content {
  overflow-y: auto;
  overflow-x: hidden;
}

.p-post-news .m-post-news-content textarea {
  font-family: inherit;
  line-height: 0.36rem;
  font-size: 0.3rem;
  resize: none;
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  bottom: 0;
  padding: 0.3rem 0.2rem;
}

.p-post-news .m-post-news-row {
  font-size: 0.3rem;
  padding: 0.4rem 0.3rem;
}

.p-post-news .m-post-news-row .m-entry-append {
  margin-left: 0.1rem;
}

.p-post-news .m-post-news-row-label {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  -ms-flex-item-align: start;
  align-self: flex-start;
  width: 1.5rem;
}

.p-post-news .m-post-news-row input {
  height: 100%;
  width: 100%;
  line-height: 0.3rem;
  font-size: 0.28rem;
}

.p-post-news .m-post-news-row .c-textarea-input {
  text-align: right;
}

.p-post-news .placeholder {
  color: #ccc;
}