.p-news-search {
  height: -webkit-calc(100% - 0.9rem);
  height: calc(100% - 0.9rem);
}

.p-news-search .m-head-top-title {
  padding: 0 0.2rem 0 0;
}

.p-news-search .m-head-top-title .m-search-box {
  width: 100%;
}

.p-news-search .placeholder {
  width: 100%;
  height: 100%;
}

.c-search-bar {
  top: 0;
}

/deep/ .jo-loadmore-main {
  margin-top: 0.9rem;
}