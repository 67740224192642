.news__filter {
  position: fixed;
  left: 0;
  right: 0;
  top: 0.9rem;
  z-index: 9;
  background-color: #fff;
  padding-top: 0 !important;
  min-height: 0.8rem;
  max-height: 0.8rem;
  max-width: 7.68rem;
  margin: 0 auto;
  font-size: 0.28rem;
  overflow: hidden;
  -webkit-transform: max-height 0.3s ease;
  transform: max-height 0.3s ease;
  border-bottom: 1px solid #ededed;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.news__filter--head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 0.3rem;
  height: 0.8rem;
  background-color: #f4f5f5;
  display: none;
}

.news__filter--head button {
  padding: 0;
  display: inline;
  outline: 0;
  border: 0;
  background-color: inherit;
  color: #59b6d7;
  margin-left: 0.6rem;
}

.news__filter--switch {
  width: 0.8rem;
  height: 0.78rem;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2;
  background: -webkit-gradient(linear, left top, right top, color-stop(0, rgba(255, 255, 255, 0.1)), color-stop(40%, white), to(white));
  background: linear-gradient(to right, rgba(255, 255, 255, 0.1) 0, white 40%, white);
}

.news__filter--switch svg {
  width: 0.24rem;
  height: 0.24rem;
  color: #999;
  margin-left: 0.373333rem;
  vertical-align: middle;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.news__filter--list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow-x: auto;
  margin-left: -0.25rem;
  margin-right: -0.25rem;
  -webkit-overflow-scrolling: touch;
}

.news__filter--list::-webkit-scrollbar-thumb,
.news__filter--list::-webkit-scrollbar {
  width: 0;
}

.news__filter--list__wrap {
  padding: 0 0.25rem;
  height: 0.8rem;
  line-height: 0.8rem;
}

.news__filter--list__label {
  display: none;
  color: #999;
}

.news__filter--list__item {
  cursor: pointer;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  color: #999;
  position: relative;
}

.news__filter--list__item:not(:first-child):after {
  content: "×";
  font-size: 16px;
  color: #999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: -0.15rem;
  margin-top: -0.15rem;
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 100%;
  background-color: rgba(127, 127, 127, 0.1);
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: -webkit-transform 0.2s ease;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

.news__filter--list__item.active {
  color: #333;
  font-size: 0.3rem;
}

.news__filter--list__item:last-child {
  padding-right: 0.8rem;
}

.news__filter.showAll {
  max-height: 100%;
  bottom: 0;
}

.showAll .news__filter--head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.showAll .news__filter--list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 0.3rem;
  margin-left: -0.15rem;
  margin-right: -0.15rem;
}

.showAll .news__filter--list__label {
  display: block;
}

.showAll .news__filter--list__item {
  margin: 0.15rem;
  height: 0.6rem;
  line-height: 0.6rem;
  padding: 0 1em;
  text-align: center;
  border-radius: 0.08rem;
  background-color: #f4f5f5;
}

.showAll .news__filter--list__wrap {
  margin-top: 0.6rem;
  padding: 0 0.3rem;
  width: 100%;
  height: auto;
  line-height: 1;
}

.editing .news__filter--list__item:after {
  -webkit-transform: scale(1);
  transform: scale(1);
}